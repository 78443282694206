import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../service";
import { constant } from '../constant'
import { CustomerRequest } from '@app/interfaces/interfaces';
const { path } = constant;

export const fetchCustomerList = createAsyncThunk(
    'customer/fetchCustomer',
    (arg:any, { rejectWithValue }) => DataService.get(arg,  path+'agent/customer/v1/list', rejectWithValue)
)

export const createUpdateCustomer = createAsyncThunk(
    'customer/createUpdateCustomer',
    (arg:CustomerRequest, { rejectWithValue }) => DataService.post(arg, path+'agent/customer/v1', rejectWithValue)
)

export const deleteCustomer = createAsyncThunk(
    'customer/deleteCustomer',
    (arg:any, { rejectWithValue }) => DataService.delete(arg, path+`agent/customer/v1/delete/${arg?.customerId}`, rejectWithValue)
)

export const toggleActivation = createAsyncThunk(
    'customer/toggleActivation',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`agent/customer/v1/toggle-status/${arg?.customerId}`, rejectWithValue)
)

export const changeCustomerPassword = createAsyncThunk(
    'customer/changeCustomerPassword',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'agent/customer/v1/change-password', rejectWithValue)
)

export const changeCustomerMasterPassword = createAsyncThunk(
    'customer/changeCustomerMasterPassword',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'agent/customer/v1/change-master-password', rejectWithValue)
)

export const updateCustomerFund = createAsyncThunk(
    'customer/updateCustomerFund',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'agent/customer/v1/update-fund', rejectWithValue)
)

export const getCustomerByCustomerId = createAsyncThunk(
    'customer/getCustomerByCustomerId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`agent/customer/v1/single/${arg?.customerId}`, rejectWithValue)
)

export const getCustomerFundByCustomerId = createAsyncThunk(
    'customer/getCustomerFundByCustomerId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`agent/customer/v1/transactions/${arg?.customerId}`, rejectWithValue)
)
export const fetchBettingList = createAsyncThunk(
    'customer/fetchBettingList',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`agent/customer/v1/bettings/${arg?.customerId}`, rejectWithValue)
)
