import React, { useCallback, useMemo, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { useResetFormOnCloseModal } from './useResetFormOnCloseModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { changeCustomerMasterPassword } from "@app/store/actions/customerAction";
import { CustomerChangePassword } from '@app/interfaces/interfaces';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
interface ChangeMasterPasswordProps {
  open: boolean;
  onCancel: () => void;
  customerDetail: any
}


export const ChangeMasterPassword: React.FC<ChangeMasterPasswordProps> = ({ open, onCancel,  customerDetail }) => {
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  useResetFormOnCloseModal({
    form,
    open,
  });

 




  const onFinish = (values: CustomerChangePassword) => {
      setLoading(true);
      const inputData ={
        customerId: customerDetail?._id,
        newPassword: values?.newPassword,
        masterPassword: values?.masterPassword
      }
     
      dispatch(changeCustomerMasterPassword(inputData)).then(({payload}) => {
        if (payload) {
          if (!payload?.success) {
            notificationController.error({ message: payload?.message });
          }
          else {
            notificationController.success({ message: payload?.message });
            onCancel()
          }
        }
        setLoading(false);
      })
      .catch((err:any) => {
        console.log("error", err)
        setLoading(false);
      });
    }
 
 

    return (
      <BaseModal
        open={open}
        onCancel={onCancel}
        footer={[]}
      >
       
        <BaseForm form={form} layout="vertical" name="adminForm" onFinish={onFinish} requiredMark="optional" initialValues={initialFormValues}>
          <Auth.FormTitle>{t('common.change-password')}</Auth.FormTitle>
          {/* <S.Description>{t('newPassword.description')}</S.Description> */}
          <S.UserDetail>
          {customerDetail && <>User Name: <S.HighLighted>{customerDetail.userName}</S.HighLighted> <br></br> Full Name: <S.HighLighted>{customerDetail.fullName}</S.HighLighted>  </>}
          </S.UserDetail>
          
          <Auth.FormItem
            name="newPassword"
            label={t('common.new-password')}
            rules={[
              { required: true, message: t('common.requiredField') },
              { min: 8, message: t('common.password-min-rule') },
          ]}
          >
            <Auth.FormInputPassword placeholder={t('common.new-password')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="confirmPassword"
            label={t('common.confirmPassword')}
            dependencies={['newPassword']}
            rules={[
              { required: true, message: t('common.requiredField') },
             
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('common.confirmPasswordError')));
                },
              }),
            ]}
            hasFeedback
          >
            <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="masterPassword"
            label={t('common.master-password')}
            rules={[
              { required: true, message: t('common.requiredField') },
              { min: 8, message: t('common.password-min-rule') },
          ]}
          >
            <Auth.FormInputPassword placeholder={t('common.master-password')} />
          </Auth.FormItem>
          <BaseForm.Item noStyle>
            <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.change-password')}
            </S.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </BaseModal>
    );
};
