import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../service";
import { constant } from '../constant'
const { path } = constant;

export const fetchBetList = createAsyncThunk(
    'bet/fetchBetList',
    (arg:any, { rejectWithValue }) => DataService.get(arg,  path+'admin/account/v1/bets', rejectWithValue)
)
export const getBetByBetId = createAsyncThunk(
    'bet/getBetByBetId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/account/v1/bets/${arg?.betId}`, rejectWithValue)
)

export const fetchCurrentBet = createAsyncThunk(
    'bet/fetchCurrentBet',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/account/v1/current-bet`, rejectWithValue)
)

export const fetchNumberWiseBetting = createAsyncThunk(
    'bet/fetchNumberWiseBetting',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/account/v1/number-wise-bet/${arg?.betId}`, rejectWithValue)
)

export const bettingUsersList = createAsyncThunk(
    'bet/bettingUsersList',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`agent/account/v1/betting-users`, rejectWithValue)
)


