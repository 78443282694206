import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { persistUser, readUser } from '@app/services/localStorage.service';

const initialState: any = {
  bets: null,
  error: null,
  isLoader: false,
  isBetRecordUpdated: false,
  pagination: null,
  currentBet:null,
  betById:null,

};

export const betSlice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    setCurrentBet: (state, { payload }) => {
      state.currentBet = payload;
    },
    setLiveBet: (state, { payload }) => {
      state.currentBet = payload;
    },
   
    betReset: (state) => {
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isBetUpdated = false;
      state.error = null;
    },
  },

  
});
// Action creators are generated for each case reducer function
export const { setCurrentBet} = betSlice.actions;

export default betSlice.reducer;
