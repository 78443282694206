import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { MasterSecuritySettings } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/MasterSecuritySettings';

const MasterSettingsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('profile.nav.securitySettings.masterPassword')}</PageTitle>
      <MasterSecuritySettings />
    </>
  );
};

export default MasterSettingsPage;
