import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerTables } from '@app/components/customer/CustomerTables';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const CustomerPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>Customers</PageTitle>
      <CustomerTables />
    </>
  );
};

export default CustomerPage;