import React, { useEffect, useState, createContext  } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate,useParams } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { CustomerProfileInfo } from '@app/components/profile/profileCard/CustomerInfo/CustomerProfileInfo';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ProfileNav } from '@app/components/profile/profileCard/CustomerNav/ProfileNav';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { getCustomerByCustomerId } from '@app/store/actions/customerAction';
import { getCustomerFundByCustomerId } from '@app/store/actions/customerAction';

export const CustomerContext = createContext()
const CustomerProfileLayout: React.FC = () => {

  const [customerDetails, setCustomerDetails] = useState();

  const {
   currentCustomerId
  } = useAppSelector((state) => state.customer);
  const dispatch = useAppDispatch();
 


  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();

  const { isTablet } = useResponsive();

  const isTitleShown = isTabletOrHigher || (mobileOnly && location.pathname === '/customer');
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/customer');

  useEffect(() => {
    isTablet && location.pathname === '/customer' && navigate(`customer-info/${currentCustomerId}`);
  }, [isTablet, location.pathname, navigate]);

  useEffect(() => {
    if(currentCustomerId==''){
      navigate('/customers')
    }
    else{
      dispatch(getCustomerByCustomerId({ customerId:currentCustomerId })).then(({ payload }) => {
        if (payload?.success) {
          setCustomerDetails(payload.data);
        }
      });
    } 

  }, [currentCustomerId]);

  return (
    <CustomerContext.Provider value={customerDetails}>
      <PageTitle>{t('profile.title')}</PageTitle>
      {!isTitleShown && (
        <Btn icon={<LeftOutlined />} type="text" onClick={() => navigate(`/customer`)}>  
          {t('common.back')}
        </Btn>
      )}

      <BaseRow gutter={[30, 30]}>
        {isTitleShown && (
          <BaseCol xs={24} md={24} xl={8}>
            <ProfileCard>
              <BaseRow gutter={[30, 30]}>
                <BaseCol xs={24} md={12} xl={24}>
                  <CustomerProfileInfo />
                </BaseCol>

                <BaseCol xs={24} md={12} xl={24}>
                  <ProfileNav />
                </BaseCol>
              </BaseRow>
            </ProfileCard>
          </BaseCol>
        )}

        {isMenuShown && (
          <BaseCol xs={24} md={24} xl={16}>
            <Outlet />
          </BaseCol>
        )}
      </BaseRow>
    </CustomerContext.Provider>
  );
};

const ProfileCard = styled(BaseCard)`
  height: unset;
`;

const Btn = styled(BaseButton)`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  padding: 0;
  height: unset;
  color: var(--secondary-color);
`;

export default CustomerProfileLayout;
