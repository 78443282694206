import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import profileImage from '@app/assets/images/profile.png';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

export const ProfileInfo: React.FC = () => {
  const [fullness,setFullness] = useState(90);
  const admin = useAppSelector((state) => state.admin);
  const { t } = useTranslation();
  useEffect(() => {
    if(admin.adminDetail?.fullName){
      setFullness(100)
    }
  }, [admin]);
  return admin ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar shape="circle" src={profileImage} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{admin.adminDetail?.fullName}</S.Title>
      <S.Subtitle>{admin.adminDetail?.userName}</S.Subtitle>
      <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
    </S.Wrapper>
  ) : null;
};
