import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { CustomerState } from '@app/interfaces/interfaces';
import { persistUser, readUser } from '@app/services/localStorage.service';
import {
  fetchCustomerList,
  createUpdateCustomer,
  toggleActivation,
  deleteCustomer,
  changeCustomerPassword,
  changeCustomerMasterPassword,
  updateCustomerFund,
  getCustomerByCustomerId,
  getCustomerFundByCustomerId,
  fetchBettingList
} from '../actions/customerAction';

const initialState: CustomerState = {
  customers: null,
  bettingList: null,
  customerPersonalInfo: null,
  customerFundInfo: null,
  error: null,
  sortBy: null,
  isLoader: false,
  isRecordAdded: false,
  isCustomerRecordUpdated: false,
  isCustomerFundUpdated: false,
  isRecordUpdated: false,
  pagination: null,
  currentCustomerId:'',
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    customerReset: (state) => {
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isCustomerFundUpdated = false;
      state.isCustomerRecordUpdated = false;
      state.error = null;
    },

    setCurrentCustomer:(state, { payload }) => {
      state.currentCustomerId = payload;
      state.customerPersonalInfo=null;
      state.customerFundInfo=null;
      state.bettingList=null;
    },
  },
  extraReducers: (builder) => {
     // fetch fund of Customer //
     builder.addCase(getCustomerFundByCustomerId.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
      state.isCustomerFundUpdated =false;
    });
    builder.addCase(getCustomerFundByCustomerId.fulfilled, (state, { payload }) => {
      state.error = null;
      state.customerFundInfo= payload?.data;
      state.isCustomerFundUpdated =true;
      state.isLoader = false;
    });

    builder.addCase(getCustomerFundByCustomerId.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
     
    });
    // end fetch fund of Customer //

    // fetch single Customer //
    builder.addCase(getCustomerByCustomerId.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
      state.isCustomerRecordUpdated =false;
    });
    builder.addCase(getCustomerByCustomerId.fulfilled, (state, { payload }) => {
      state.error = null;
      state.customerPersonalInfo= payload?.data;
      state.isCustomerRecordUpdated =true;
      state.isLoader = false;
    });

    builder.addCase(getCustomerByCustomerId.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
      
    });
    // fetch single Customer //


    // fetch Customers //
    builder.addCase(fetchCustomerList.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
    });
    builder.addCase(fetchCustomerList.fulfilled, (state, { payload }) => {
      state.error = null;
      return {
        ...state,
        ...payload?.data,
        isLoader: false,
      };
    });
    builder.addCase(fetchCustomerList.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
    });
    // end fetch Customers //

    // createUpdateCustomer //
    builder.addCase(createUpdateCustomer.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(createUpdateCustomer.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(createUpdateCustomer.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end createUpdateCustomer //

    // toggleActivation //
    builder.addCase(toggleActivation.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(toggleActivation.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(toggleActivation.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end toggleActivation //

    // deleteCustomer //
    builder.addCase(deleteCustomer.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(deleteCustomer.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(deleteCustomer.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end deleteCustomer //

    // changeCustomerPassword //
    builder.addCase(changeCustomerPassword.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(changeCustomerPassword.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(changeCustomerPassword.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end changeCustomerPassword //

    // changeCustomerMasterPassword //
    builder.addCase(changeCustomerMasterPassword.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(changeCustomerMasterPassword.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(changeCustomerMasterPassword.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end changeCustomerMasterPassword //

    // updateCustomerFund //
    builder.addCase(updateCustomerFund.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updateCustomerFund.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updateCustomerFund.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end updateCustomerFund //

     // fetchBettingList //
     builder.addCase(fetchBettingList.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(fetchBettingList.fulfilled, (state, { payload }) => {
      state.error = null;
      return {
        ...state,
        ...payload?.data,
        isLoader: false,
      };
    });
    builder.addCase(fetchBettingList.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end fetchBettingList //
  },
});
// Action creators are generated for each case reducer function
export const {setCurrentCustomer} = customerSlice.actions;

export default customerSlice.reducer;
