import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { persistUser, readUser } from '@app/services/localStorage.service';
import {
getSettingList,
} from '../actions/settingAction';

const initialState: any = {
  settings: null,
  error: null,
  isLoader: false,
  isSettingRecordUpdated: false,
  isRecordUpdated: false,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    agentReset: (state) => {
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isSettingRecordUpdated = false;
    
      state.error = null;
    },
  },
  extraReducers: (builder) => {
   
    // fetch settings //
    builder.addCase(getSettingList.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
    });
    builder.addCase(getSettingList.fulfilled, (state, { payload }) => {
      state.error = null;
      
        state.settings = payload?.data; 
        state.isLoader = false;
     
    });
    builder.addCase(getSettingList.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
    });
    // end fetch agents //


    

  },
});
// Action creators are generated for each case reducer function
export const {} = settingSlice.actions;

export default settingSlice.reducer;
