import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ThemePicker } from '../../header/components/settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';
import { LanguagePickerIcon } from '../../header/components/settingsDropdown/settingsOverlay/LanguagePicker/LanguagePickerIcon';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
  shouldShowSearch: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout, shouldShowSearch }) => {
  const { isPWASupported, event } = useAppSelector((state) => state.pwa);
  const { t } = useTranslation();
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={10} xxl={12}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          {shouldShowSearch && <HeaderSearch />}
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={6} xxl={8}>
        {shouldShowSearch && <HeaderSearch />}
      </BaseCol>
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}
      <S.ProfileColumn $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[8, 8]}>
          <BaseCol>
            <ThemePicker />
          </BaseCol>
          <BaseCol>
            <LanguagePickerIcon />
          </BaseCol>
          <BaseCol>
            <HeaderFullscreen />
          </BaseCol>
          <BaseCol>
            <BaseRow>
              <BaseCol>
                {isPWASupported && (
                  <BaseButton block type="primary" size={'small'} onClick={() => event && (event as BeforeInstallPromptEvent).prompt()}>
                    {t('common.pwa')}
                  </BaseButton>
                )}
              </BaseCol>
            </BaseRow>
          </BaseCol>
          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};