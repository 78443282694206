import axios from "axios";
import { constant } from '../store/constant';

const { path } = constant;

class DataService {

    post(arg, endpoint, rejectWithValue) {
        const authDetail = localStorage.getItem('authorization');
        if (authDetail) {
            const token = JSON.parse(authDetail)?.token;
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        }

        return axios.post(endpoint, arg).then(({ data }) => {
            if (endpoint === path + 'admin/auth/v1/login' || endpoint === path + 'agent/auth/v1/login') {
                if (data.code === 200) {
                    localStorage.setItem('authorization', JSON.stringify(data?.data));
                    axios.defaults.headers.common['Authorization'] = `Bearer ${data?.data?.token}`;
                }
            }
            return data;
        }).catch(error => {
            const { data } = error.response;
            if (data.code === 502 || data.code === 403) {
                return rejectWithValue("Something went wrong, please refresh or try again");
            } else if (data.code === 500 && data.message === "jwt expired") {
                localStorage.removeItem('authorization');
                window.location.replace('/auth/login');
            }
            return rejectWithValue(error.response.data);
        });
    }

    get(arg, endpoint, rejectWithValue) {
        const authDetail = localStorage.getItem('authorization');
        console.log("auth", authDetail);
        if (authDetail) {
            const token = JSON.parse(authDetail)?.token;
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        }

        return axios.get(endpoint, { params: arg }).then(({ data }) => {
            return data;
        }).catch(error => {
            const { data } = error.response;
            if (data.code === 502 || data.code === 403 ) {
                return rejectWithValue("Something went wrong, please refresh or try again");
            }
            return rejectWithValue(error.response.data);
        });
    }

    delete(arg, endpoint, rejectWithValue) {
        const authDetail = localStorage.getItem('authorization');
        if (authDetail) {
            const token = JSON.parse(authDetail)?.token;
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        }

        return axios.delete(endpoint, { params: arg }).then(({ data }) => {
            return data;
        }).catch(error => {
            const { data } = error.response;
            if (data.code === 502 || data.code === 403) {
                return rejectWithValue("Something went wrong, please refresh or try again");
            } else if (data.code === 500 || data.code === 401) {
                localStorage.removeItem('authorization');
                window.location.replace('/login');
            }
            return rejectWithValue(error.response.data);
        });
    }
}

export default new DataService();
