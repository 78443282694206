import { BellOutlined, DollarOutlined, SecurityScanOutlined, UserOutlined } from '@ant-design/icons';
import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success';
  href: string;
}


export const profileCustomerNavData: ProfileNavItem[] = [
  
  {
    id: 1,
    name: 'profile.nav.personalInfo.title',
    icon: <UserOutlined />,
    color: 'primary',
    href: `customer-info`,
  },
  {
    id: 2,
    name: 'common.transactionInfo',
    icon: <SecurityScanOutlined />,
    color: 'success',
    href: `customer-fund-info`,
  },

  {
    id: 2,
    name: 'common.betInfo',
    icon: <SecurityScanOutlined />,
    color: 'success',
    href: `customer-bet-info`,
  },
];
