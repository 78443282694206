import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';



export const UserTableList: React.FC = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<{ data: BasicTableRow[];  loading: boolean }>({
    data: [],
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();

 

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      
    });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'User Name',
      dataIndex: 'name',
      render: (text: string) => <span>{text}</span>,
      filterMode: 'tree',
      filterSearch: true,
      filters: [
        {
          text: t('common.firstName'),
          value: 'firstName',
          children: [
            {
              text: 'Joe',
              value: 'Joe',
            },
            {
              text: 'Pavel',
              value: 'Pavel',
            },
            {
              text: 'Jim',
              value: 'Jim',
            },
            {
              text: 'Josh',
              value: 'Josh',
            },
          ],
        },
        {
          text: t('common.lastName'),
          value: 'lastName',
          children: [
            {
              text: 'Green',
              value: 'Green',
            },
            {
              text: 'Black',
              value: 'Black',
            },
            {
              text: 'Brown',
              value: 'Brown',
            },
          ],
        },
      ],
      onFilter: (value: string | number | boolean, record: BasicTableRow) => record.name.includes(value.toString()),
    },
    {
      title: 'Full Name',
      dataIndex: 'fullname',
    },
    {
      title: 'Mobile No.',
      dataIndex: 'mobile',
    },
    {
      title: 'Password',
      dataIndex: 'password',
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
    },
    {
      title: 'Opening Balance',
      dataIndex: 'opneningBlc',
    },
    {
      title: 'Credit Reference',
      dataIndex: 'credit',
    },
    // {
    //   title: t('common.age'),
    //   dataIndex: 'age',
    //   sorter: (a: BasicTableRow, b: BasicTableRow) => a.age - b.age,
    //   showSorterTooltip: false,
    // },
    {
      title: 'Exposer Limit',
      dataIndex: 'exposerLimit',
    },
    {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (tags: Tag[]) => (
        <BaseRow gutter={[10, 10]}>
          {/* {tags.map((tag: Tag) => {
            return (
              <BaseCol key={tag.value}>
                <Status color={defineColorByPriority(tag.priority)} text={tag.value.toUpperCase()} />
              </BaseCol>
            );
          })} */}
          <BaseCol>
            <Status color={'blue'} text={'Active'} />
          </BaseCol>
        </BaseRow>
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <BaseSpace>
            <BaseButton
              type="ghost"
              onClick={() => navigate("/profile/personal-info")}
            >
              {'View Detail'}
            </BaseButton>
            <BaseButton type="default" danger onClick={() => handleDeleteRow(record.key)}>
              {t('tables.delete')}
            </BaseButton>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      loading={tableData.loading}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
