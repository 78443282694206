import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import profileImage from '@app/assets/images/profile.png';
export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const admin = useAppSelector((state) => state.admin);

  return admin ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar src={profileImage} alt="User" shape="circle" size={40} />
        </BaseCol>
        {isTablet && (
          <BaseCol>
            <span>{admin?.adminDetail?.userName}</span>
          </BaseCol>
        )}
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
