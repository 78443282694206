import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { BettingTableList } from './BettingTableList';
import { NumberWiseBetTable } from './NumberWiseBetTable';

import { setCurrentBet } from '@app/store/slices/betSlice';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

import { useTranslation } from 'react-i18next';
import * as S from './BetTable.styles';
import { BaseTabs, TabPane } from '@app/components/common/BaseTabs/BaseTabs';
import { Counter } from '@app/components/counter';
import { socket } from '@app/socket';

export const BettingTabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(socket.connected);
  const {
    bets,
    isLoader,
    pagination,
    isRecordUpdated,
    currentBet,
    error: apiError,
  } = useAppSelector((state) => state.bet);
  const {
    adminDetail,

  } = useAppSelector((state) => state.admin);

  useEffect(() => {
    function onConnect() {
      socket.emit('message', 'send current bet info');

    }

    function onDisconnect() {
  console.log("disconnected")
    }

    socket.on('connect', onConnect);
    
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);  
    };
  }, []);

  useEffect(() => {
    socket.on(`messageResponseToAgent`, ({data,agentId}:any) => {

      if(agentId==adminDetail.id && data ){
       dispatch(setCurrentBet(data));
      }
          
         });
    }
  , [ isConnected, socket]);


  return (
    <>
      <S.Card>
        {currentBet && (
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                Start Time:{' '}
                {currentBet?.startTime && (
                  <>{new Date(currentBet?.startTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>
                )}
              </p>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                End Time:{' '}
                {currentBet?.endTime && (
                  <>{new Date(currentBet?.endTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>
                )}
              </p>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                Close Mode: {currentBet?.closeMode && <>{currentBet?.closeMode}</>}
              </p>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                Status: {currentBet?.status && <>{currentBet?.status}</>}
              </p>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
              {currentBet?.status === 'OPEN' && moment(currentBet?.endTime)?.isAfter(moment(new Date())) && (
                <Counter endTime={currentBet?.endTime} />
              )}
            </div>
            {currentBet?.status =='OPEN' &&  <BaseTabs defaultActiveKey="1">
          <TabPane tab={`${t('common.number-wise-bet')}`} key="1">
            <S.TablesWrapper>
              <NumberWiseBetTable />
            </S.TablesWrapper>
          </TabPane>
          <TabPane tab={`${t('common.betting-user-list')}`} key="2">
            <S.TablesWrapper>
              <BettingTableList betId={currentBet?._id} />
            </S.TablesWrapper>
          </TabPane>
          {/* <TabPane tab={`${t('common.summary')}`} key="3">
            <S.TablesWrapper>
             <SummaryBetTable/>
             </S.TablesWrapper>
            </TabPane> */}
          
          </BaseTabs>

        }
          </>
        )}
        
       
        </S.Card>
       
      
    </>
  );
};
