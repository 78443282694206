import React from 'react';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { AndroidOutlined } from '@ant-design/icons';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ThemePicker } from '../../header/components/settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';
import { LanguagePickerIcon } from '../../header/components/settingsDropdown/settingsOverlay/LanguagePicker/LanguagePickerIcon';
import { SiderLogo } from '@app/components/layouts/main/sider/SiderLogo';
import logo from 'assets/logo.png';

import * as R from '@app/components/layouts/main/sider/MainSider/MainSider.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const { isPWASupported, event } = useAppSelector((state) => state.pwa);
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const {
    settings,
    error: apiError,
  } = useAppSelector((state) => state.setting);

  const img = settings?.logo ? settings?.logo?.link : logo;

  return (
    <BaseRow justify="space-between" align="middle">
      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
      {isMobile && <BaseCol>
      <R.SiderLogoLink to="/">
        <img src={img} alt="Bhagyalaxmi" width={40} height={40} />
        
      </R.SiderLogoLink>
      </BaseCol>}

      <BaseCol>
        <BaseRow align="middle" gutter={[5, 5]}>
          <BaseCol>
            <ThemePicker />
          </BaseCol>
          <BaseCol>
            <LanguagePickerIcon />
          </BaseCol>
          <BaseCol>
          {isPWASupported && (
            <BaseButton type="primary" size={'small'} shape="circle" icon={<AndroidOutlined />} onClick={() => event && (event as BeforeInstallPromptEvent).prompt()}>
               </BaseButton>
      )}
          </BaseCol>
          <BaseCol>
        <ProfileDropdown />
      </BaseCol>
        </BaseRow>
      </BaseCol>

      
    </BaseRow>
  );
};
