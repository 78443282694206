import userReducer from '@app/store/slices/userSlice';
import customerReducer from '@app/store/slices/customerSlice';
import adminReducer from '@app/store/slices/adminSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import settingReducer from '@app/store/slices/settingSlice';
import betReducer from '@app/store/slices/betSlice';

export default {
  user: userReducer,
  customer: customerReducer,
  admin: adminReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  setting:settingReducer,
  bet:betReducer
};
