import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { getSettingList } from '@app/store/actions/settingAction';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { Link } from 'react-router-dom';
import logo from 'assets/logo.png';
const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { settings, isLoader, isSettingRecordUpdated, error: apiError } = useAppSelector((state) => state.setting);

  const img = settings?.logo ? settings?.logo?.link : logo;
  useEffect(() => {
    dispatch(getSettingList());
  }, [isSettingRecordUpdated]);
  const { t } = useTranslation();

  return (
    <>
     <Link to="/home" >
    <img src={img} style={{width:'100px', height:'100px',marginBottom:'10px'}} alt='logo' />
    </Link>
      <PageTitle>{t('common.login')}</PageTitle>
      <LoginForm />
    </>
  );
};

export default LoginPage;
