import React from 'react';
import { useTranslation } from 'react-i18next';
import { profileCustomerNavData } from '@app/constants/profileCustomerNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import * as S from './ProfileNav.styles';

export const ProfileNav: React.FC = () => {
  const { t } = useTranslation();
  const {
    currentCustomerId:customerId
   } = useAppSelector((state) => state.customer);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
    {
      customerId && <S.Wrapper>
      {profileCustomerNavData.map((item) => (
        <S.Btn
          key={item.id}
          icon={item.icon}
          type="text"
          color={item.color}
          onClick={() => navigate(`/customer/${item.href}/${customerId}`)}
          $isActive={`/customer/${item.href}/${customerId}` === location.pathname}
        >
          {t(item.name)}
        </S.Btn>
      ))}
    </S.Wrapper>
    }
    
    </>
  );
};
