import React from 'react';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { MasterPasswordForm } from './passwordForm/PasswordForm/MasterPasswordForm';

export const MasterSecuritySettings: React.FC = () => (
  <BaseCard>
    <BaseRow gutter={[30, 0]}>
      <BaseCol xs={24} >
        <MasterPasswordForm />
      </BaseCol>
    </BaseRow>
  </BaseCard>
);
