import { NumericLiteral } from 'typescript';

export type Dimension = number | string;

export type ChartData = number[];

export type xData = number[] | string[];

export type LanguageType = 'in' | 'en';

export type ThemeType = 'light' | 'dark';

export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type TwoFactorAuthOption = 'email' | 'phone';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export enum CurrencyTypeEnum {
  INR ='INR',
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
}

export interface PaymentCard {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  // eslint-disable-next-line
  focused: any;
  background: string;
  isEdit: boolean;
}


export interface CustomerRequest {
  _id?:string;
  customerId?:string;
  userName: string;
  fullName: string;
  mobile: string;
  exposerLimit: number,
  password?:string;
  masterPassword?:string;

}

export interface CustomerChangePassword {
  customerId:string;
  newPassword: string;
  masterPassword: string;
}
export interface AdminChangePassword {
  
  newPassword: string;
  masterPassword: string;
  confirmPassword:string;
}
export interface CustomerFundUpdate {
  customerId?:string;
  amount: number;
  transactionType?: string;
  remark: string;
}

export interface LoginRequest {
  userName: string;
  password: string;
}

export interface Pagination {
  totalItems?:number;
  currentCount?:number;
  itemsPerPage?:number;
  currentPage?:number;
  hasNextPage?:boolean,
  hasPreviousPage?:boolean,
  totalPages?:number
}

export interface UserModel {
  fullName: string;
  mobile: string;
  id: number | string;
  _id: number | string;
  userName: string;
  email: string;
}


export interface CustomerModel {
  fullName: string;
  mobile: string;
  id: number | string;
  _id: number | string;
  userName: string;
  email: string;
}


export interface AdminState {
  isAdminLoggedIn: boolean,
  adminDetail: UserModel | null;
  error: string|null,
  isLoader: boolean,
  isRecordUpdated: boolean,
  invalidJwtToken: boolean,
  customerFundInfo: [] | null;
  dashboardData: {mainBalance:number, totalCustomer:number} | null;
    isCustomerFundUpdated: boolean,
}

export interface CustomerFundDetail {
  accountBalance: number;
  customerId: number | string;
  customer:string;
  remark:string;
  transactionType:string;
  createdAt:string|number;
  updatedAt:string|number;

}
export interface CustomerFund {
  transactionList: [CustomerFundDetail];
  pagination: Pagination;

}


export interface CustomerState {
  customers:[CustomerModel]| null,
  bettingList:[]| null,
  customerPersonalInfo: CustomerModel | null,
  customerFundInfo: CustomerFund | null,
  error: string|null,
  isLoader: boolean,
  sortBy: string|null,
  isRecordAdded: boolean,
  pagination:null|Pagination,
  isRecordUpdated: boolean,
  isCustomerRecordUpdated: boolean,
  isCustomerFundUpdated: boolean,
  currentCustomerId:string
}