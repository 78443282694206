import { io } from 'socket.io-client';
import { constant } from './store/constant'

const { path } = constant;
// Replace with your token
const authDetail = localStorage.getItem('authorization') 
const token = JSON.parse(authDetail)?.token ||'';

export const socket = io(path, {
    auth: {
      token: token,
    },
  });