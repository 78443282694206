import styled from 'styled-components';
import { BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';
import { Collapse } from '@app/components/common/Collapse/Collapse';
export const TablesWrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;



export const InputsWrapper = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const CollapseWrapper = styled(Collapse)`
  width: 40rem;
`;

export const Timer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  margin-top: 25px;
  padding: 10px;
  text-align: center;
  width: 400px;
  `;

  export const Col = styled.div`
    width: 25%;
    float: left;
  `




