import React from 'react';
import { UserTableList } from './UserTableList';

import { useTranslation } from 'react-i18next';
import * as S from './UserTable.styles';

export const UserTables: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <S.TablesWrapper>
        <S.Card id="admin-table" title={"Customer List"} padding="1.25rem 1.25rem 0">
          <UserTableList />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};