import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import profileImage from '@app/assets/images/profile.png';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { CustomerContext } from '../../CustomerProfileLayout';

export const CustomerProfileInfo: React.FC<ChangePasswordProps> = () => {
  const customerDetails = useContext(CustomerContext);
  const [fullness, setFullness] = useState(90);
  const { t } = useTranslation();
  useEffect(() => {
    if (customerDetails?.fullName) {
      setFullness(100);
    }
  }, [customerDetails]);
  return customerDetails ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar shape="circle" src={profileImage} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{customerDetails?.fullName}</S.Title>
      <S.Subtitle>{customerDetails?.userName}</S.Subtitle>
      <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
    </S.Wrapper>
  ) : null;
};
