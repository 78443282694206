import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { socket } from '@app/socket';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

interface GameNumberType{
  displayNumber:string,
  _id:string,
  multipleTime:string|number
}

export const NumberWiseBetTable: React.FC = () => {
 
  const {
    currentBet,
    error: apiError,
  } = useAppSelector((state) => state.bet);
  const {
    adminDetail,
  } = useAppSelector((state) => state.admin);

    const [open, setOpen] = useState(false);
    const [gameNumber, setGameNumber] = useState<GameNumberType | null>(null);
    const [rowData, setRowData] = useState(null);
    const [timer, setTimer] = useState(0);
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
  });
  const [searchText, setSearchText] = useState<string | null>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const [highestprofit, setHighestProfit] = useState('');
  const [tableData, setTableData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    socket.on(`liveBetResponseToAgent`, ({liveData,agentId}) => {
       
      if(agentId==adminDetail.id && liveData){
        setTableData(liveData);  
       } 
            
    });
    }
  , [ currentBet?.status, socket]);

 


  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);



  const columns = [
    {
      title: t('common.bet-number'),
      dataIndex: 'displayNumber',
    },
    {
      title: t('common.invested-amount'),
      dataIndex: 'sumInvestedAmount',
    },
    {
      title: t('common.total-amount'),
      dataIndex: 'sumTotalAmount',
    },
    
     
  ];

 

  return (
<>

    <BaseTable
      columns={columns}
      dataSource={tableData}
     
      loading={loading}
      scroll={{ x: 800 }}
      bordered
    />
    </>
  );
};
