import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../service";
import { constant } from '../constant'
import { LoginRequest } from '@app/interfaces/interfaces';
const { path } = constant;



export const adminLogin = createAsyncThunk(
    'agent/adminLogin',
   async (arg: LoginRequest, { rejectWithValue }) => DataService.post(arg, path+'agent/auth/v1/login', rejectWithValue)
)

export const forgotPassword = createAsyncThunk(
    'agent/forgotPassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'agent/auth/v1/forgot-password', rejectWithValue)
)

export const changePassword = createAsyncThunk(
    'agent/changePassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'agent/account/v1/change-password', rejectWithValue)
)

export const changeMasterPassword = createAsyncThunk(
    'agent/changeMasterPassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'agent/account/v1/change-master-password', rejectWithValue)
)

export const resetPassword = createAsyncThunk(
    'agent/resetPassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'agent/auth/v1/reset-password', rejectWithValue)
)

export const updateProfile = createAsyncThunk(
    'agent/updateProfile',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'agent/account/v1/edit-profile', rejectWithValue)
)

export const getProfile = createAsyncThunk(
    'agent/getProfile',
    (arg, { rejectWithValue }) => DataService.get(arg, path+'agent/account/v1/profile', rejectWithValue)
)

export const getDashboard = createAsyncThunk(
    'agent/getDashboard',
    (arg, { rejectWithValue }) => DataService.get(arg, path+'agent/account/v1/dashboard', rejectWithValue)
)

export const getFund = createAsyncThunk('agent/getFund', (arg, { rejectWithValue }) =>
 DataService.get(arg, path+`agent/account/v1/transactions`, rejectWithValue)
);