import React from 'react';
import {
  DashboardOutlined,
  UserOutlined,
  SmileOutlined,
  SettingOutlined,
  PayCircleOutlined,
  LaptopOutlined
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.nft-dashboard',
    key: 'nft-dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'common.manage-customer',
    key: 'manage-customer',
    url: '/customers',
    icon: <UserOutlined />,
  },
  {
    title: 'common.transaction-history',
    key: 'history',
    url: '/payments',
    icon: <PayCircleOutlined />,
  },
  {
    title: 'common.live-betting',
    key: 'live-betting',
    url: '/bettings',
    icon: <LaptopOutlined />,
  },
];
