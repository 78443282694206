import styled from 'styled-components';
import { BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
export const TablesWrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;

export const MoveRight = styled.div`
  float: left;
`;
export const TableTitle = styled.div`
  color: var(--primary-color);
  margin-right:2rem;

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.lg};
    font-weight: ${FONT_WEIGHT.semibold};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl};
    font-weight: ${FONT_WEIGHT.semibold};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.semibold};
    line-height: 2.125rem;
  }
`;

export const FlexEnd = styled.div`
  display: flex;
  justi
`;
