import { configureStore } from '@reduxjs/toolkit';
import { errorLoggingMiddleware } from '@app/store/middlewares/errorLogging.middleware';
import rootReducer from '@app/store/slices';
import { checkAdmin } from './slices/adminSlice'

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(errorLoggingMiddleware),
});

const authDetail = localStorage.getItem('authorization') 
store.dispatch(checkAdmin(authDetail))
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
