import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { RadioBtn } from '../SettingsOverlay/SettingsOverlay.styles';
import { useLanguage } from '@app/hooks/useLanguage';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
export const LanguagePickerIcon: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  return (

    <>
    <BaseSelect width={100} size={'small'} defaultValue={language} onSelect={(value,e) => setLanguage(value)}>
            <Option value="en">English</Option>
            <Option value="in">हिंदी</Option>       
          </BaseSelect>
    </>
  );
};
